import React from 'react'
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { device } from '../../utils/device'
import { navigate, Link } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const ModalMenu = styled.div`
    && {
        position: absolute;
        z-index: 9999;
        width: 100vw;
    }
`
const ModalCard = styled.div`
    && {
        width: 90vw;
        margin-top: 50px;
        max-width: 400px;
        align-self: center;
        margin: 50px;
        @media ${device.mobile} {
            align-self: center;
        }
    }
    > .modal-card-head,
    .modal-card-foot {
        background-color: white;
        padding: 10px;
    }
    > footer {
        background-color: #01b5cc !important ;
    }
`
const ModalBody = styled.section`
    && {
        padding: 0;
    }
    > .navbar-item {
        padding: 1rem;
        font-size: 1.3rem;
        &:nth-child(even) {
            background: rgba(14, 30, 37, 0.03);
        }
    }
`
const StyledBackground = styled.div`
    && {
        background-color: rgba(10, 10, 10, 0.56);
    }
`
const CloseButton = styled.div`
    && {
        height: 23px;
        max-height: 23px;
        max-width: 23px;
        min-height: 23px;
        min-width: 23px;
        width: 23px;
        background-color: rgba(10, 10, 10, 0.35);
    }
`

const ModalWrapper = styled.div`
    && {
        justify-content: flex-start;
    }
    &.modal-enter {
        opacity: 0;
        transform: scale(0.3);
        transition: transform 0.3s cubic-bezier(0.8, -0.4, 0.2, 1.44) 0s,
            opacity 0.3s cubic-bezier(0.8, -0.4, 0.2, 1.44) 0s;
    }
    &.modal-enter-active {
        opacity: 1;
        transform: scale(1);
    }
    &.modal-exit {
        opacity: 1;
        transform: scale(1) translate(0, 0);
        transition: transform 0.3s cubic-bezier(0.8, -0.4, 0.2, 1.44) 0s,
            opacity 0.3s cubic-bezier(0.8, -0.4, 0.2, 1.44) 0s;
    }
    &.modal-exit-active {
        opacity: 0;
        transform: scale(0.2) translate(100px, -100px);
    }
`

// Named function so its not anonymous to debug
export default function Modalmenu(props) {
    const { originalPath, i18n, changeLanguage, t } = useI18next()
    const { modalMenuOpen, setModalMenuOpen } = props
    const pages = [
        ['navigation.powerplant', '/soncna-elektrarna'],
        ['navigation.community', '/energetska-skupnost'],
        /*['Mikro elektrarna', '/mikro-elektrarna'],*/
        ['navigation.vzdrzevanje', '/vzdrzevanje-svetovanje'],
        ['navigation.industry', '/industrija'],
        ['navigation.blog', '/blog'],
        ['navigation.about', '/kisik'],
    ]
    const menuItems = pages.map((item) => (
        <Link
            key={item[1]}
            to={item[1]}
            className={'navbar-item'}
            activeClassName="currentMenuItem"
            onClick={() => {
                document.documentElement.classList.remove('modal-open')
                setModalMenuOpen(false)
                navigate(item[1])
            }}
        >
            {t(item[0])}
        </Link>
    ))

    return (
        <ModalMenu>
            <TransitionGroup component={null}>
                <CSSTransition
                    timeout={500}
                    classNames="modal"
                    key={modalMenuOpen}
                    appear={true}
                >
                    <ModalWrapper
                        className={`modal ${
                            modalMenuOpen ? 'is-active' : null
                        }`}
                    >
                        <StyledBackground
                            className={'modal-background'}
                            onClick={() => {
                                document.documentElement.classList.remove(
                                    'modal-open'
                                )
                                setModalMenuOpen(false)
                            }}
                        />
                        <ModalCard className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title" />
                                <CloseButton
                                    className="delete"
                                    aria-label="close"
                                    onClick={() => {
                                        document.documentElement.classList.remove(
                                            'modal-open'
                                        )
                                        setModalMenuOpen(false)
                                    }}
                                />
                            </header>
                            <ModalBody className="modal-card-body">
                                {menuItems}
                                <a
                                    rel="noopener noreferrer"
                                    className={'navbar-item'}
                                    onClick={() => {
                                        window.localStorage.setItem(
                                            'gatsby-i18next-language',
                                            i18n.language == 'en' ? 'si' : 'en'
                                        )
                                        changeLanguage(
                                            i18n.language == 'en' ? 'si' : 'en',
                                            originalPath
                                        )
                                    }}
                                >
                                    <StaticImage
                                        style={{
                                            width: '2em',
                                            height: '1.5em',
                                            display:
                                                i18n.language == 'en'
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        src={'../../images/slovenian.png'}
                                        alt="flag"
                                        width={96}
                                    />
                                    <StaticImage
                                        style={{
                                            width: '2em',
                                            height: '1.5em',
                                            display:
                                                i18n.language == 'si'
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                        src={'../../images/english.png'}
                                        alt="flag"
                                        width={96}
                                    />
                                </a>
                            </ModalBody>
                            <footer className="modal-card-foot" />
                        </ModalCard>
                    </ModalWrapper>
                </CSSTransition>
            </TransitionGroup>
        </ModalMenu>
    )
}
